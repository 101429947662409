<template>
  <div>
    <div class="form-header font-weight-bolder px-15 py-5">{{ $t('label.authorization') }}</div>
    <div v-if="!loginFailed" class="p-15 py-sm-10">
      <floating-input-form-group
        class="mb-10 mb-sm-5"
        :value="form.username"
        :placeholder="$t('label.email')"
        input-type="email"
        :error="error.username"
        @input="form.username = $event"
      />

      <floating-input-form-group
        :value="form.password"
        :placeholder="$t('label.password')"
        input-type="password"
        :error="error.password"
        @input="form.password = $event"
      />

      <div class="mt-2 mb-15 mb-sm-10">
        <router-link to="/forgot-password" class="text-green">
          <span class="cursor-pointer">{{ $t('label.forgot_password') }}?</span>
        </router-link>
      </div>

      <div class="text-center">
        <button class="btn btn-green font-weight-bold px-9 py-4 my-3 font-size-3 w-100 mb-5" @click="submitForm">
          {{ $t('btn.sign_in') }}
        </button>

        <router-link to="/registration" class="text-green">
            <span class="cursor-pointer">
              {{ $t('btn.sign_up') }}
            </span>
        </router-link>
      </div>
    </div>
    <div v-else class="px-15 py-10">
      <div class="text-center">
        <inline-svg src="/media/svg/icons/General/Error.svg"/>
        <div class="h4 mt-2">{{ $t('error.wrong_credentials') }}</div>
        <button class="btn btn-green font-weight-bold px-9 py-4 my-3 font-size-3 w-100 mt-15"
                @click="loginFailed = false">
          {{ $t('btn.start') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {IS_AUTH, LOGIN} from '@/store/core/auth.module';
import {formValidation} from '@/mixins/formValidation';
import LocalStorageService from '../../core/services/local-storage.service';
import FloatingInputFormGroup from '@/components/elements/form-groups/FloatingInputFormGroup.vue';

export default {
  name: "login",
  components: {
    FloatingInputFormGroup
  },
  mixins: [formValidation],
  data: () => ({
    loginFailed: false,
    form: {
      username: '',
      password: ''
    },
    error: {
      username: '',
      password: ''
    }
  }),
  async beforeMount() {
    this.$emit('title', this.$t('title.sign_in'));
    if (await this.$store.dispatch(IS_AUTH) && LocalStorageService.getAuthVerified()) {
      await this.$router.push({name: this.$defaultRoute});
    }
  },
  computed: {
    ...mapGetters({
      verificationNeeded: 'VERIFICATION_NEEDED'
    }),
    formUsername() {
      return this.form.username;
    },
    formPassword() {
      return this.form.password;
    }
  },
  watch: {
    verificationNeeded(val) {
      if (val) {
        this.$router.push({name: 'verification'});
      }
    },
    formUsername(val) {
      if (val) this.error.username = '';
    },
    formPassword(val) {
      if (val) this.error.password = '';
    }
  },
  methods: {
    validateForm() {
      this.error.username = this.validateEmail(this.form.username);
      this.error.password = !this.form.password ? 'placeholder.enter_password' : '';

      return !Object.values(this.error).some(v => (v !== null && v !== ''));
    },
    submitForm() {
      if (!this.validateForm()) return false;

      let credentials = this.form;
      const deviceId = LocalStorageService.getDeviceUuid();
      if (deviceId) credentials.device_id = deviceId;

      this.$store.dispatch(LOGIN, credentials).catch(() => {
        this.clearForm();
        this.clearErrors();
        this.loginFailed = true;
      });
    },
    clearForm() {
      for (let item in this.form) this.form[item] = '';
    },
    clearErrors() {
      for (let item in this.error) this.form[item] = '';
    },
  }
};
</script>

<style lang="scss" scoped>
.form-header {
  border-bottom: 1px solid #6E7173;
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .form-header {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    font-size: 16px;
  }
}
</style>